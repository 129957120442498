import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { UserConsentStatusEnum } from '../models/user-consent-status.enum';
import { type UserConsent } from '../models/user-consent.model';
import { type UserConsentResource } from '../models/user-consent.resource';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class UserConsentMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.UserConsent;

    public map(resource: UserConsentResource): UserConsent | undefined {
        if (resource.attributes.data.length === 0) {
            return undefined;
        }

        return {
            id: resource.attributes.data[0].id,
            status: resource.attributes.data[0].status ?? UserConsentStatusEnum.OPEN,
        };
    }
}
