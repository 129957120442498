export const mapInitials = (fullName: string | undefined): string | undefined => {
    if(!fullName) {
        return undefined;
    }

    const names = fullName.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if(names.length > 1) {
        initials += names[names.length -1].substring(0, 1).toUpperCase();
    }

    return initials;
}
