import { catchError } from 'rxjs/operators';
import { Injectable, inject } from '@angular/core';
import { type HttpInterceptor, type HttpEvent, type HttpHandler, type HttpRequest, type HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { type Observable, throwError } from 'rxjs';
import { UserService } from '../state/user.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
    private readonly userService: UserService = inject(UserService);

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {
                if ([HttpStatusCode.Unauthorized].includes(err.status)) {
                    this.userService.logout();
                }

                return throwError(() => err);
            }),
        );
    }
}
