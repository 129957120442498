import { inject, Injectable, Injector } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { createStore, withProps, select } from '@ngneat/elf';
import type { Observable } from 'rxjs';

export interface UserState {
    user: {
        token?: string;
    }
}

export const userStore = createStore(
    { name: 'user' },
    withProps<UserState>({ user: { token: undefined } }),
);

@Injectable({ providedIn: 'root' })
export class UserRepository {
    private readonly injector = inject(Injector);

    public state$ = userStore.pipe(
        takeUntilDestroyed(),
        select(state => state.user),
    );

    public isLoggedIn = toSignal(userStore.pipe(
        select(state => Boolean(state.user.token)),
    ), { injector: this.injector });

    public update(user: UserState['user']): void {
        userStore.update(state => ({
            ...state,
            user,
        }));
    }

    public getToken(): UserState['user']['token'] {
        return userStore.getValue().user.token;
    }

    public setToken(token: UserState['user']['token']): void {
        this.update({ token });
    }

    public isLoggedIn$(): Observable<boolean> {
        return this.state$.pipe(
            select(state => Boolean(state.token)),
        );
    }
}
