import { Injectable, inject } from '@angular/core';
import { type HttpInterceptor, type HttpEvent, type HttpHandler, type HttpRequest } from '@angular/common/http';
import { type Observable } from 'rxjs';
import { UserService } from '../state/user.service';

@Injectable()
export class AuthorizationHeaderInterceptor implements HttpInterceptor {
    private readonly userService: UserService = inject(UserService);

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // eslint-disable-next-line no-param-reassign
        req = req.clone({
            // eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style, @typescript-eslint/sort-type-constituents
            setHeaders: this.userService.getAuthorizationHeader() as { [name: string]: string | string[] },
        });

        return next.handle(req);
    }
}
