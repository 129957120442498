import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { type BonusMeasureCategory } from '../models/bonus-measure-category.model';
import { type BonusMeasure } from '../models/bonus-measure.model';
import { type BonusMeasures } from '../models/bonus-measures.model';
import {
    type BonusMeasureResource,
    type BonusMeasuresResource,
    type BonusMeasuresResourceBookable,
    type BonusMeasuresResourceBooked,
} from '../models/bonus-measures.resource';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class BonusMeasuresMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.BonusMeasures;

    public map(resource: BonusMeasuresResource): BonusMeasures | undefined {
        return {
            bookable: this.mapBookableMeasures(resource.attributes.bookable),
            booked: this.mapBookedMeasures(resource.attributes.booked),
            hasBookableMeasures: this.hasBookableMeasures(resource.attributes.bookable),
            canBookMeasures: resource.attributes.canBookMeasures,
            bookingYear: resource.attributes.bookingYear,
        };
    }

    /**
     * Returns a list of booked measures with a category in it
     * @param {BonusMeasuresResourceBooked} measures booked bonus measures resource
     * @returns {BonusMeasure[]} booked measures with name, amount and it's category
     */
    private mapBookedMeasures(measures: BonusMeasuresResourceBooked): BonusMeasure[] {
        const mappedMeasures: BonusMeasure[] = [];

        // does this ever happen?
        if (Array.isArray(measures)) {
            return [];
        }

        for (const categoryKey of Object.keys(measures)) {
            Object.values(measures[categoryKey].data).forEach((measure: BonusMeasureResource): void => {
                mappedMeasures.push({
                    name: measure.name,
                    amount: measure.amount,
                    category: measures[categoryKey].label,
                });
            });
        }

        return mappedMeasures;
    }

    /**
     * Returns a list of bookable measures categories with their bonus measures in it
     * @param {BonusMeasuresResourceBookable} measures bookable bonus measures resource
     * @returns {BonusMeasureCategory[]} bookable measures categories with label and their bonus measures
     */
    private mapBookableMeasures(measures: BonusMeasuresResourceBookable): BonusMeasureCategory[] {
        const bonusMeasuresCategories: BonusMeasureCategory[] = [];

        // does this ever happen?
        if (Array.isArray(measures)) {
            return [];
        }

        for (const categoryKey of Object.keys(measures)) {
            const category: BonusMeasureCategory = {
                label: measures[categoryKey].label,
                comment: measures[categoryKey].comment,
                measures: [],
            };

            Object.values(measures[categoryKey].data).forEach((measure: BonusMeasureResource): void => {
                category.measures.push({
                    amount: measure.amount,
                    details: measure.details,
                    name: measure.name,
                    category: measures[categoryKey].label,
                });
            });

            bonusMeasuresCategories.push(category);
        }

        return bonusMeasuresCategories;
    }

    /**
     * Checks for items in the category "VM"
     * @param {BonusMeasuresResourceBookable} measures bookable bonus measures resource
     * @returns {boolean} a boolish flag that tells about bookable measures in the category "VM"
     */
    private hasBookableMeasures(measures: BonusMeasuresResourceBookable): boolean {
        if (Array.isArray(measures)) {
            return false;
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/dot-notation
        const relevantMeasures = { ...(measures['VM']?.data ?? []), ...(measures['VW']?.data ?? []) };

        return !!Object.keys(relevantMeasures).length;
    }
}
