import { inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { ResourceTypes, RestApiClientService } from '@big-direkt/rest-api-client';
import { EnvironmentService, WindowService } from '@big-direkt/utils/environment';
import { firstValueFrom, of, tap, type Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { type AuthorizationHeader } from '../models/authorization-header';
import { type BonusMeasures } from '../models/bonus-measures.model';
import { type UserConsentIdEnum } from '../models/user-consent-ids.enum';
import { type UserProfileModel } from '../models/user-profile.model';
import { UserRoleModel } from '../models/user-roles.type';
import { type UserConsent } from './../models/user-consent.model';
import { UserRepository } from './user.repository';

// TODO: create own service for autologout handling and info modal in BIGRED-858
@Injectable({ providedIn: 'root' })
export class UserService {
    private readonly environmentService = inject(EnvironmentService);
    private readonly restApiClient = inject(RestApiClientService);
    private readonly router = inject(Router);
    private readonly userRepository = inject(UserRepository);
    private readonly windowService = inject(WindowService);

    public readonly userConsentById = signal<UserConsent | undefined>(undefined);

    private profileData: {
        withCatalogs: Observable<UserProfileModel | undefined> | undefined;
        withoutCatalogs: Observable<UserProfileModel | undefined> | undefined;
    } = { withCatalogs: undefined, withoutCatalogs: undefined };

    public getProfile(withCatalogs = false, useCache = true): Observable<UserProfileModel | undefined> {
        let profileDataObservable: Observable<UserProfileModel | undefined> | undefined = this.profileData[withCatalogs ? 'withCatalogs' : 'withoutCatalogs'];

        if (!profileDataObservable || !useCache) {
            /* eslint-disable @stylistic/ts/indent */
            profileDataObservable = this.profileData[withCatalogs ? 'withCatalogs' : 'withoutCatalogs'] = this.userRepository.isLoggedIn$().pipe(
                switchMap(isLoggedIn => {
                    if (!isLoggedIn) {
                        return of(undefined);
                    }

                    return this.restApiClient
                        .load<UserProfileModel>(ResourceTypes.UserProfile, undefined, {
                            params: {
                                cb: Date.now(),
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                with_catalogs: withCatalogs,
                            },
                        })
                        .pipe(shareReplay({
                            bufferSize: 1,
                            refCount: true,
                        }));
                }),
                shareReplay({
                    bufferSize: 1,
                    refCount: true,
                }),
            );
            /* eslint-enable @stylistic/ts/indent */
        }

        return profileDataObservable;
    }

    public getBonusMeasures(year: number | undefined): Observable<BonusMeasures> {
        const options = {
            params: {
                cb: Date.now(),
                ...(year !== undefined && { ...{ year } }),
            },
        };

        return this.restApiClient.load<BonusMeasures>(ResourceTypes.BonusMeasures, undefined, options);
    }

    public logout(): void {
        void firstValueFrom(this.restApiClient.post(ResourceTypes.UserLogout, undefined, undefined, { headers: this.getAuthorizationHeader() }));
        this.userRepository.setToken(undefined);

        this.profileData = { withCatalogs: undefined, withoutCatalogs: undefined };

        const { privatePath } = this.environmentService;

        // only redirect to login when user is under privateContentPath
        if (this.router.url.startsWith(privatePath)) {
            const destination = this.router.url.replace('logout', '');
            void this.router.navigateByUrl(`${privatePath}/login?destination=${destination}`);
        } else if (this.windowService.nativeWindow()?.location.pathname.startsWith(privatePath)) {
            const destination = this.windowService.nativeWindow()?.location.pathname.replace('logout', '');
            void this.router.navigateByUrl(`${privatePath}/login?destination=${destination}`);
        }
    }

    public getAuthorizationHeader(): AuthorizationHeader {
        if (this.userRepository.getToken() === undefined) {
            return {};
        }

        return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: `Bearer ${this.userRepository.getToken() ?? ''}`,
        };
    }

    public updateUserConsentById(id: UserConsentIdEnum): void {
        void firstValueFrom(
            this.restApiClient
                .load<UserConsent | undefined>(ResourceTypes.UserConsent, undefined, {
                    params: {
                        cb: Date.now(),
                        id,
                    },
                })
                .pipe(
                    tap(res => {
                        this.userConsentById.set(res);
                    }),
                ),
        );
    }

    public hasRole(roleToCheck: UserRoleModel[] | UserRoleModel): Observable<boolean | undefined> {
        const safeguardedRole = !Array.isArray(roleToCheck) ? [roleToCheck] : roleToCheck;

        return this.getProfile().pipe(map(profile => safeguardedRole.some(r => profile?.roles.includes(r))));
    }

    public getCustomerSegment(): Observable<string | undefined> {
        return this.getProfile().pipe(map(profile => profile?.customerSegment));
    }
}
