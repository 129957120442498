/* eslint-disable sonarjs/cognitive-complexity */
import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { type BigtionaerPaymentStatusResource, type BigtionaerPaymentStatusRewardsResource } from '../models/user-profile-catalogs.resource';
import { type UserProfileModel } from '../models/user-profile.model';
import { type UserProfileResource } from '../models/user-profile.resource';
import { mapInitials } from './map-initials';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class UserProfileMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.UserProfile;

    public map(resource: UserProfileResource): UserProfileModel {
        return {
            address: {
                city: resource.attributes.address.city ?? undefined,
                country: resource.attributes.address.country ?? undefined,
                housenumber: resource.attributes.address.housenumber ?? undefined,
                state: resource.attributes.address.state ?? undefined,
                street: resource.attributes.address.street ?? undefined,
                zip: resource.attributes.address.zip ?? undefined,
            },
            identifier: {
                healthInsuranceNumber: resource.attributes.identifier.health_insurance_number ?? undefined,
                taxIdentificationNumber: resource.attributes.identifier.tax_identification_number ?? undefined,
            },
            name: {
                fullTitle: resource.attributes.name.full_insurant_title ?? undefined,
                surname: resource.attributes.name.surname ?? undefined,
                initials: mapInitials([resource.attributes.name.forename, resource.attributes.name.surname].join(' ')),
            },
            contact: {
                phone: {
                    private: resource.attributes.contact.phone.private ?? undefined,
                    mobile: resource.attributes.contact.phone.mobile ?? undefined,
                    business: resource.attributes.contact.phone.business ?? undefined,
                },
                email: {
                    business: resource.attributes.contact.email.business ?? undefined,
                    private: resource.attributes.contact.email.private ?? undefined,
                },
            },
            birthdate: resource.attributes.birthdate,
            catalogs: this.mapCatalogs(resource),
            roles: resource.attributes.prefs,
            customerSegment: resource.attributes.customer_segment,
        };
    }

    private mapCatalogs(resource: UserProfileResource): UserProfileModel['catalogs'] {
        const catalogs: UserProfileModel['catalogs'] = {};

        if (Array.isArray(resource.attributes.catalogs)) {
            return catalogs;
        }

        for (const key of Object.keys(resource.attributes.catalogs)) {
            catalogs[key] = {
                catalogId: resource.attributes.catalogs[key].catalog_id,
                paymentStatus: undefined,
                paymentRequirementsFulfilled: undefined,
            };

            const paymentStatus: BigtionaerPaymentStatusResource | undefined = resource.attributes.catalogs[key].payment_status;

            if (paymentStatus) {
                catalogs[key] = {
                    catalogId: resource.attributes.catalogs[key].catalog_id,
                    paymentStatus: {
                        availableOptions: paymentStatus.available_options,
                        currentAmount: paymentStatus.current_amount,
                        cashAmount: paymentStatus.cash_amount,
                        cashDate: paymentStatus.cash_date,
                        optionDate: paymentStatus.option_date,
                        optionId: paymentStatus.option_id,
                        rewards: paymentStatus.rewards?.map((rewardResource: BigtionaerPaymentStatusRewardsResource) => ({
                            amount: rewardResource.amount ?? undefined,
                            note: rewardResource.note,
                            entryDate: rewardResource.entry_date,
                            type: rewardResource.type,
                        })),
                    },
                    paymentRequirementsFulfilled: resource.attributes.catalogs[key].payment_requirements_fulfilled,
                };
            }
        }

        return catalogs;
    }
}
