import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MapperInjectorService, RESOURCE_PATH_TOKEN, ResourceTypes, RestApiClientModule } from '@big-direkt/rest-api-client';
import { AuthorizationHeaderInterceptor } from './interceptors/authorization-header.interceptor';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { BonusMeasuresMapper } from './response-mapper/bonus-measures.mapper';
import { UserConsentMapper } from './response-mapper/user-consent.mapper';
import { UserProfileMapper } from './response-mapper/user-profile.mapper';

@NgModule({
    imports: [RestApiClientModule],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizationHeaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.UserLogout]: '/users/logout' }, multi: true },
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.UserProfile]: '/users/profile' }, multi: true },
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.UserConsent]: '/users/insurant/consents' }, multi: true },
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.BonusMeasures]: '/api/bigtionaer/measures' }, multi: true },
        { provide: MapperInjectorService.tokens[BonusMeasuresMapper.type], useClass: BonusMeasuresMapper },
        { provide: MapperInjectorService.tokens[UserConsentMapper.type], useClass: UserConsentMapper },
        { provide: MapperInjectorService.tokens[UserProfileMapper.type], useClass: UserProfileMapper },
    ],
})
export class UserModule {}
